import React from 'react'
import laptevSrc from '../assets/images/team/laptev.png'
import pavlovSrc from '../assets/images/team/pavlov.png'
import dubodelovaSrc from '../assets/images/team/dubodelova.png'
import srebnySrc from '../assets/images/team/srebny.png'

const Contacts = () => {
  return (
    <>  
        <section className="section inner-banner-sec absolute top-0 w-full"/>
        <section className="section heading-sec sec-top-spacing trigger">
            <div className="container">
                <div className="heading-content">
                    <h4 className="inner-heading-spacing text-white fade-in fade-in-top">Contact Us</h4>
                    <h2 className='text-white fade-in fade-in-top delay-100'>Connect with our team - we&apos;re here to help</h2>
                </div>
            </div>
        </section>
        <section className="section contact-sec sec-top-spacing trigger">
            <div className="container">
                <h2 className="inner-heading-spacing fade-in fade-in-top">Contact us</h2>
                <div className="grid grid-cols-2 mb-20 gap-8">
                    <div className="contact-col fade-in fade-in-top delay-100">
                        <img src={srebnySrc} alt="" loading='lazy' className='mx-auto'/>
                        <div className="flex flex-col justify-center items-center">
                            <h2>Alex Srebny</h2>
                            <a href="mailto:alex@easyrentor.com">alex@easyrentor.com</a>
                        </div>
                    </div>

                    <div className="contact-col fade-in fade-in-top delay-200">
                        <img src={pavlovSrc} alt="" loading='lazy' className='mx-auto'/>
                        <div className="flex flex-col justify-center items-center">
                            <h2>Ilya Pavlov</h2>
                            <a href="mailto:ilja.pavlov@easyrentor.com">ilja.pavlov@easyrentor.com</a>
                        </div>
                    </div>

                    <div className="contact-col fade-in fade-in-top delay-300">
                        <img src={dubodelovaSrc} alt="" loading='lazy' className='mx-auto'/>
                        <div className="flex flex-col justify-center items-center">
                            <h2>Jelizaveta Dubodelova</h2>
                            <a href="mailto:jelizaveta@easyrentor.com">jelizaveta@easyrentor.com</a>
                        </div>
                    </div>

                    <div className="contact-col fade-in fade-in-top delay-400">
                        <img src={laptevSrc} alt="" loading='lazy' className='mx-auto'/>
                        <div className="flex flex-col justify-center items-center">
                            <h2>Ilya Laptev</h2>
                            <a href="mailto:ilya.laptev@easyrentor.com">ilya.laptev@easyrentor.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Contacts
