import React from 'react'

const Terms = () => {
    return (
        <>
            <section className="inner-banner-sec" />
            <section className="terms-sec sec-top-spacing sec-bottom-spacing">
                <div className="container">
                    <div className="terms-wrap">
                    <div className="terms-heading-sec inner-heading-spacing">
                        <h2>Terms and Conditions</h2>
                        <p>
                            Welcome to EasyRentor, a company providing innovative solutions for digital contract management, powered by AI technology. Our platform helps you streamline your rental agreements, making the process more efficient, secure, and transparent. Please read the following Terms and Conditions carefully, as they apply to your use of our platform.
                        </p>
                    </div>
                    <ul className="terms-content">
                        <li>
                            <h4>1. Acceptance of Terms</h4>
                            <p>
                                By using EasyRentor, you agree to be bound by these Terms and Conditions, as well as our Privacy Policy. If you do not agree to these terms, you must discontinue using our platform.
                            </p>
                        </li>
                        <li>
                            <h4>2. Services Offered</h4>
                            <p>
                                EasyRentor provides a digital contract management platform that utilizes AI technology to streamline the rental agreement process. Our platform includes features such as automatic contract generation, electronic signature collection, and secure storage of documents.
                            </p>
                        </li>
                        <li>
                            <h4>3. Account Registration</h4>
                            <p>
                                To use our platform, you must create an account and provide accurate and complete information. You are responsible for maintaining the confidentiality of your account information, and any activity that occurs under your account.
                            </p>
                        </li>
                        <li>
                            <h4>4. Use of our Platform</h4>
                            <p>
                                You agree to use our platform for lawful purposes only. You may not use our platform to transmit any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable.
                            </p>
                        </li>
                        <li>
                            <h4>5. Intellectual Property</h4>
                            <p>
                                All content on our platform, including but not limited to text, graphics, logos, and software, is the property of EasyRentor or its licensors and is protected by copyright and other intellectual property laws. You may not reproduce, modify, distribute, or otherwise use any content on our platform without our express written permission.
                            </p>
                        </li>
                        <li>
                            <h4>6. Disclaimer of Warranties</h4>
                            <p>
                                EasyRentor provides our platform &quot;as is&quot; and without any warranty or representation of any kind, whether express, implied, or statutory. We do not guarantee that our platform will meet your requirements, be uninterrupted, secure, or error-free.
                            </p>
                        </li>
                        <li>
                            <h4>7. Limitation of Liability</h4>
                            <p>
                                In no event shall EasyRentor be liable to you or any third party for any damages, including but not limited to direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of our platform.
                            </p>
                        </li>
                        <li>
                            <h4>8. Indemnification</h4>
                            <p>
                                You agree to indemnify and hold harmless EasyRentor and its affiliates, officers, agents, and employees from any claim or demand, including reasonable attorneys&apos; fees, arising out of or in connection with your use of our platform or your breach of these Terms and Conditions.
                            </p>
                        </li>
                        <li>
                            <h4>9. Modification of Terms</h4>
                            <p>
                                EasyRentor reserves the right to modify these Terms and Conditions at any time. We will notify you of any material changes to these terms by posting a notice on our website. Your continued use of our platform after the changes become effective constitutes your acceptance of the revised terms.
                            </p>
                        </li>
                        <li>
                            <h4>10. Governing Law</h4>
                            <p>
                                These Terms and Conditions are governed by and construed in accordance with the laws of the state of California, without giving effect to any principles of conflicts of law.
                            </p>
                        </li>
                        <li>
                            <h4>11. Entire Agreement</h4>
                            <p>
                                These Terms and Conditions, together with our Privacy Policy, constitute the entire agreement between you and EasyRentor and govern your use of our platform.
                            </p>
                        </li>
                    </ul>
                    <p className="terms-connect">
                        If you have any questions or concerns about these Terms and Conditions, please contact us at
                        {' '}
                        <a href="mailto:support@easyrentor.com">support@easyrentor.com</a>
                        .
                    </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Terms
