import React from 'react';
import PropTypes from 'prop-types';

const TeamMember = ({ name, description, imageSrc }) => {
    return (
        <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl hover:-translate-y-2">
            <div className="relative aspect-square overflow-hidden">
                <img 
                    src={imageSrc} 
                    alt={name} 
                    loading='lazy' 
                    className="w-full h-full object-cover grayscale transition-all duration-300 hover:grayscale-0"
                />
            </div>
            <div className="p-4">
                <h4 className="text-xl font-bold mb-2">{name}</h4>
                <p className="text-gray-600 text-sm">{description}</p>
            </div>
        </div>
    );
};

TeamMember.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imageSrc: PropTypes.string.isRequired,
};

export default React.memo(TeamMember);