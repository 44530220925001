import React from 'react'
import TeamMember from '../components/TeamMember'
import laptevSrc from '../assets/images/team/laptev.png'
import pavlovSrc from '../assets/images/team/pavlov.png'
import dubodelovaSrc from '../assets/images/team/dubodelova.png'
import srebnySrc from '../assets/images/team/srebny.png'
import shahabSrc from '../assets/images/team/shahab.png'
import cagriSrc from '../assets/images/team/cagri.png'

const AboutUs = () => {
    const founders = [
        { name: "Alex Srebny", description: "Project management and SaaS expert with a passion for data-driven decisions and efficiency.", imageSrc: srebnySrc },
        { name: "Ilya Pavlov", description: "ML engineer with 4 years of experience in computer vision, passionate about automation and music.", imageSrc: pavlovSrc },
        { name: "Jelizaveta Dubodelova", description: "Creative designer and marketer with 3 years of experience, bringing innovation to every project.", imageSrc: dubodelovaSrc },
        { name: "Ilya Laptev", description: "Software wizard with 5 years of experience, turning ideas into reality through coding.", imageSrc: laptevSrc },
    ];

    const investors = [
        { name: "Shahab Anbarjafari", description: "Professor and Chief Data Scientist, expert in computer vision and AI, founder of iCV research lab.", imageSrc: shahabSrc },
        { name: "Cagri Ogizcan", description: "Experienced Machine Learning Scientist specializing in AI computer vision and audio solutions.", imageSrc: cagriSrc },
    ];

    return (
        <>
            <section className="banner-first trigger">
                <div className="banner-overlay" />
                <div className="container">
                    <div className="banner-content">
                        <h1 className='fade-in fade-in-top delay-100'>Meet our TEAM</h1>
                    </div>
                </div>
            </section>
            <section className='bg-white section trigger'>
                <div className="container flex flex-col justify-center items-center">
                    <h4 className="inner-heading-spacing fade-in fade-in-top delay-100">Our story</h4>
                    <h2 className='fade-in fade-in-left delay-200'>Hello world, we are the team from Riga, let us introduce ourselves and get to know each other better</h2>
                </div>
            </section>
            <section className='bg-slate-100 section py-16 trigger'>
                <div className='container mx-auto'>
                    <h2 className="text-3xl font-bold text-center mb-12">Our team</h2>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8'>
                        {founders.map((founder, index) => (
                            <TeamMember key={index} {...founder} />
                        ))}
                    </div>
                </div>
            </section>
            <section className='bg-slate-200 section py-16 trigger'>
                <div className='container mx-auto'>
                    <h2 className="text-3xl font-bold text-center mb-12">Our Angel Investors</h2>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto'>
                        {investors.map((investor, index) => (
                            <TeamMember key={index} {...investor} />
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUs