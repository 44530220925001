import Layout from "../pages/Layout"
import Home from "../pages/Home"
import AboutUs from "../pages/AboutUs"
import Contacts from "../pages/Contacts"
import Terms from "../pages/Terms"
import NotFound from "../pages/NotFound"


export const routes = [
    {
        path: '',
        element: <Layout/>,
        children: [
            {
                path: '',
                element: <Home/>
            },
            {
                path: '/about',
                element: <AboutUs/>
            },
            {
                path: '/contacts',
                element: <Contacts/>
            },
            {
                path: '/terms',
                element: <Terms/>
            },
        ]
    },

    {
        path: '*',
        element: <NotFound/>
    },
]