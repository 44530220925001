import { React, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Header from '../components/Header'
import { Outlet } from 'react-router-dom'
import Footer from '../components/Footer'

const Layout = () => {
    const location = useLocation();

    useEffect(() => {
        const setupObserver = () => {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.querySelectorAll('.fade-in').forEach(el => {
                            el.classList.add('fade-show');
                        });
                    } else {
                        entry.target.querySelectorAll('.fade-in').forEach(el => {
                            el.classList.remove('fade-show');
                        });
                    }
                });
            }, { threshold: 0.2 });

            document.querySelectorAll('.trigger').forEach((el) => {
                observer.observe(el);
            });

            return observer;
        };

        const observer = setupObserver();

        return () => observer.disconnect();
    }, [location]);

    return (
        <> 
            <Header/>
            <div>
                <Outlet/>
            </div>
            <Footer/>
        </>
    )
}

export default Layout