import { BrowserRouter } from "react-router-dom";
import { useEffect, useState } from 'react';
import AppRouter from "./routes/AppRouter";
import HashLoader from 'react-spinners/HashLoader';
import ScrollTop from "./components/ScrollTop";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'

const queryClient = new QueryClient()


const App = () => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const handlePageLoad = () => {
            setIsLoading(false);
        };

        window.addEventListener('load', handlePageLoad);
        return () => window.removeEventListener('load', handlePageLoad);
    }, []);

    if (isLoading) {
        return (
            <div className="mx-auto flex flex-col w-full h-screen items-center justify-center">
                <HashLoader
                    color="#005eb5"
                    isLoading
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
                {' '}
            </div>
        );
    }

    

    return (
        <>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <ScrollTop/>
                    <AppRouter/>
                </BrowserRouter>
            </QueryClientProvider>
        </>
    );
};

export default App;