

const JoinButton = () => {
    
    const handleMainApp = () => {
        window.location.href = 'https://app.easyrentor.com'
    }
    
    return (
        <div className="flex flex-col items-center justify-center w-36">
            <button type="button" className="bg-sky-400 mt-20 w-1/2 px-4 py-2 rounded-xl transition duration-700 font-bold ease-in-out hover:bg-sky-500 hover:shadow-2xl text-white lg:w-full lg:mt-0 lg:p-2"
                    onClick={handleMainApp}>
                        Join
            </button>
        </div>
        );
    };
  
  export default JoinButton;