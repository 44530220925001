import React, { useEffect } from 'react';
import houseSrc from '../assets/images/mask-1.png';
import solutionSrc from '../assets/images/mask-2.png';
import phoneSrc from '../assets/images/phone.png';
import '../animation.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faClock, faMobileAlt, faLock } from '@fortawesome/free-solid-svg-icons';
import Journey from '../components/Journey'
import ScrollToTopButton from '../components/ScrollToTopButton';


const Home = () => {
    const benefits = [
    {
        title: 'Enhanced Accuracy',
        description: 'AI-generated property reports based on before and after photos protect both parties by documenting damages precisely.',
        icon: faCheckCircle
    },
    {
        title: 'Increased Convenience',
        description: 'Our app simplifies contract management, allowing remote signing and review, eliminating the need for in-person meetings.',
        icon: faClock
    },
    {
        title: 'Improved Accessibility',
        description: 'Access your e-contracts from any internet-connected device, perfect for managing multiple properties.',
        icon: faMobileAlt
    },
    {
        title: 'Heightened Security',
        description: 'Digital contracts offer superior security with password protection and automatic backups, reducing risks of loss or tampering.',
        icon: faLock
    },
    ];

    const landlordSteps = [
        "Create an account on our platform",
        "Add your property details and upload photos",
        "Generate and customize your contract",
        "Share the contract with your tenant"
    ];

    const tenantSteps = [
        "Receive a link with the instructions",
        "Create your profile",
        "Review and sign digital contract",
        "During the checkout take photos of the property"
    ];

    const landlordImages = [
        phoneSrc,
        phoneSrc,
        phoneSrc,
        phoneSrc
    ];

    const tenantImages = [
        phoneSrc,
        phoneSrc,
        phoneSrc,
        phoneSrc
    ];

  return (
    <>
        {/* Banner */}
        <section className="section banner-sec trigger">
            <div className='container'>
                <div className="flex flex-col justify-center items-center">
                    <h1 className="inner-heading-spacing fade-in fade-in-top delay-100 text-white">Simplify property management with our AI-powered solution</h1>
                    <p className='pro-btn fade-in fade-in-left delay-200'>Try it now</p>
                </div>
            </div>
        </section>

        {/* What is EasyRentor */}
        <section className="section heading-sec trigger">
            <div className='container'>
                <div className="heading-content text-center">
                    <h4 className="fade-in fade-in-top delay-100 inner-heading-spacing">What we are</h4>
                    <h2 className="fade-in fade-in-top delay-200">Unique AI that finds damage in interior photos and immediately captures it in a legally verifiable contract</h2>
                </div>
            </div>
        </section>

        {/* Problem */}
        <section className="section trigger">
            <div className='container'>
                <div className="flex flex-col justify-center items-left md:w-7/12 bg-white bg-opacity-75 p-4 rounded-lg">
                    <h2 className="fade-in fade-in-left mb-6">Problem statement</h2>
                    <p className="fade-in fade-in-left delay-100">Renting can be risky and time-consuming, with lengthy contracts that often lack itemized lists and condition reports. Landlords may face financial risks from property damage that goes unnoticed or undocumented.</p>
                </div>
            </div>
            {/* bg image */}
            <div className="fade-in fade-in-top absolute right-0">
                <img src={houseSrc} alt="House illustration" className='opacity-65 w-full'/>
            </div>
        </section>

        {/* Solution */}
        <section className="section trigger relative">
            {/* bg image */}
            <div className="solution-img fade-in fade-in-left absolute left-0">
                <img src={solutionSrc} alt="Solution illustration" className='opacity-65 w-full'/>
            </div>
            <div className='container flex justify-end'>
                <div className="flex flex-col justify-right items-center w-full md:w-5/12 bg-white bg-opacity-75 p-4 rounded-lg">
                    <h2 className="fade-in fade-in-right inner-heading-spacinge">What is our solution?</h2>
                    <ul>
                        <li className="fade-in fade-in-right delay-100 mt-8">
                            <h4 className='mb-4'>Smart Digital Contracts</h4>
                            <p>Simplify contract creation with our intuitive digital tools, streamlining property management.</p>
                        </li>
                        <li className="fade-in fade-in-right delay-200 mt-8">
                            <h4 className='mb-4'>AI-Powered Property Inspection</h4>
                            <p>Our advanced AI scans photos to detect damages, estimate repair costs, and ensure comprehensive coverage.</p>
                        </li>
                        <li className="fade-in fade-in-right delay-300 mt-8">
                            <h4 className='mb-4'>Quick and Easy Signing</h4>
                            <p>Create, share, and sign multi-language contracts in just two clicks, saving time and reducing complexity.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>

         {/* Landlords journey */}
        <section className="section work-sec trigger">
            <div className='container'>
                <div className="bg-gray-100 rounded-lg p-8 shadow-lg">
                    <div className="flex items-center justify-center">
                    <div className="md-w-2/3 pr-8">
                        <div className="work-content flex flex-col justify-center items-center select-none">
                        <h2 className="fade-in fade-in-top delay-100">For Landlords</h2>
                        <ul className="work-list">
                            <li className="fade-in fade-in-top delay-200">
                                <div className="list-num"><span>1</span></div>
                                <div className="list-text">Create an account on our platform</div>
                            </li>
                            <li className="fade-in fade-in-top delay-300">
                                <div className="list-num"><span>2</span></div>
                                <div className="list-text">Add your property details and upload photos</div>
                            </li>
                            <li className="fade-in fade-in-top delay-400">
                                <div className="list-num"><span>3</span></div>
                                <div className="list-text">Generate and customize your contract</div>
                            </li>
                            <li className="fade-in fade-in-top delay-500">
                                <div className="list-num"><span>4</span></div>
                                <div className="list-text">Share the contract with your tenant</div>
                            </li>
                        </ul>
                        </div>
                    </div>
                    <div className="w-1/3 fade-in fade-in-right delay-200 max-sm:hidden">
                        <img src={phoneSrc} alt="Mobile app illustration" className="w-full" />
                    </div>
                    </div>
                </div>
            </div>
        </section>

        {/* Tenant's journey */}
        <section className="section work-sec trigger">
            <div className='container'>
                <div className="bg-gray-100 rounded-lg p-8 shadow-lg">
                    <div className="flex items-center justify-center">
                        <div className="w-1/3 fade-in fade-in-left delay-200 max-sm:hidden">
                            <img src={phoneSrc} alt="Mobile app illustration" className="w-full" />
                        </div>
                        <div className="md-w-2/3 pr-8">
                            <div className="work-content flex flex-col justify-center items-center select-none">
                            <h2 className="fade-in fade-in-right delay-100">For Tenants</h2>
                            <ul className="work-list">
                                <li className="fade-in fade-in-top delay-200">
                                    <div className="list-num"><span>1</span></div>
                                    <div className="list-text">Receive a link with the instructions</div>
                                </li>
                                <li className="fade-in fade-in-top delay-300">
                                    <div className="list-num"><span>2</span></div>
                                    <div className="list-text">Create your profile</div>
                                </li>
                                <li className="fade-in fade-in-top delay-400">
                                    <div className="list-num"><span>3</span></div>
                                    <div className="list-text">Review and sign digital contract</div>
                                </li>
                                <li className="fade-in fade-in-top delay-500">
                                    <div className="list-num"><span>4</span></div>
                                    <div className="list-text">During the checkout take photos of the property</div>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* Benefit list */}
        <section className="section banefit-sec trigger">
            <div className='container mx-auto px-4'>
            <h2 className="text-center fade-in fade-in-top delay-100 inner-heading-spacing">Key Benefits</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-20">
                    {benefits.map((benefit, index) => (
                        <div className={`text-center fade-in fade-in-top delay-${200 + index * 100}`} key={index}>
                            <div className="bg-blue-100 rounded-full p-4 inline-block mb-4">
                                <FontAwesomeIcon icon={benefit.icon} className="text-blue-600 text-3xl" />
                            </div>
                            <div className="banefit-content">
                                <h4>{benefit.title}</h4>
                                <p>{benefit.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>

        {/* Contact form */}
        <section className='trigger'>
            <div className='flex flex-row justify-center items-center w-full'>
                <div className='w-3/4 p-6 flex flex-col justify-center items-center text-center'>
                    <h2 className="text-center fade-in fade-in-top delay-100 inner-heading-spacing">Get in Touch</h2>
                    <form className="mt-8 w-full md:w-4/5 lg:w-3/4">
                        <div className="fade-in fade-in-top delay-200">
                            <input type="text" name="name" id="name" placeholder="Your name" className="w-full mt-2 p-3 border rounded-xl" />
                        </div>
                        <div className="fade-in fade-in-top delay-200">
                            <input type="email" name="email" id="email" placeholder="Your email" className="w-full mt-2 p-3 border rounded-xl" />
                        </div>
                        <div className="fade-in fade-in-top delay-200">
                            <textarea name="message" id="message" rows="4" placeholder="Your message" className="w-full mt-2 p-3 border rounded-xl"></textarea>
                        </div>
                        <div className="mt-8 text-center fade-in fade-in-top delay-200">
                            <button className="pro-btn" type="submit">Send Message</button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
        {/* Scroll to Top button */}
        <ScrollToTopButton/>
    </>
  );
};

export default Home;