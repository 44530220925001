import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Journey = ({ steps, title, images }) => {
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveStep((prevStep) => (prevStep + 1) % steps.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [steps.length]);

    return (
        <div className="">
            <h2 className="text-center mb-8">{title}</h2>
            <div className="flex items-center">
                <div className="w-1/3">
                    <AnimatePresence mode="wait">
                        <motion.img
                            key={activeStep}
                            src={images[activeStep]}
                            alt={`Step ${activeStep + 1}`}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            className={`w-full rounded-lg`}
                        />
                    </AnimatePresence>
                </div>
                <div className="p-8 glass-card">
                    <div className="work-content flex flex-col justify-center items-center select-none">
                        <ul className="work-list">
                            {steps.map((step, index) => (
                                <li
                                    key={index}
                                    className={`cursor-pointer transition-all duration-300 ${
                                        index === activeStep ? 'scale-105' : 'scale-100'
                                    }`}
                                    onClick={() => setActiveStep(index)}
                                >
                                    <div className="list-num">
                                        <span>{index + 1}</span>
                                    </div>
                                    <div className="list-text">{step}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Journey;