import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import JoinButton from '../JoinButton'
import logoSrc from '../../assets/images/logo.svg'


const Header = () => {
    const [showMenu, setShowMenu] = useState(false)

    const handleToggleMenu = () => {
        setShowMenu(!showMenu)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleToggleMenu();
        }
    };

    return (
        <header className="header">
            <div className="container">
                <div className="header-main">
                    <div className="logo">
                        <Link to="/">
                            <img src={logoSrc} alt="logo" loading="lazy" />
                        </Link>
                    </div>
                    {!showMenu && (
                        <div
                        onKeyDown={handleKeyDown}
                        tabIndex={0}
                        role="button"
                        className="open-nav-menu bg-opacity-50"
                        onClick={handleToggleMenu}
                        >
                            <span />
                        </div>
                    )}
                    <nav className={`nav-menu bg-black bg-opacity-90 ${showMenu ? 'open' : ''} lg:flex lg:flex-row lg:items-center lg:bg-transparent lg:justify-around lg:w-full`}>
                        <ul className="menu flex flex-col lg:flex-row lg:items-center lg:w-full lg:justify-around">
                            <li>
                                <div className="flex flex-row float-right mr-6 mt-10">
                                    <button type="button" className="lg:hidden close-menu flex-end text-4xl hover:" onClick={() => setShowMenu(false)}>
                                    &times;
                                    </button>
                                </div>
                            </li>
                            <li className="menu-item">
                                <Link to="/" className="active" onClick={() => setShowMenu(false)}>
                                    Home
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/about" onClick={() => setShowMenu(false)}>
                                    About Us
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/contacts" onClick={() => setShowMenu(false)}>
                                    Contact Us
                                </Link>
                            </li>
                            <li className='menu-item'>
                                <JoinButton onClick={() => setShowMenu(false)} />
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    )
}

export default Header
